<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Send Sms</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleSmsSend">
                        <contact-sms-component v-model="form.sms_message" :contacts="form.contact_id" :success-handler="closeModal" source="bulk" ref="sms-component"/>
                        <div class="action_wpr mt-5">
                            <button :disabled="smsLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="smsLoader || validContacts.length == 0" class="btn save_btn" type="button" @click="handleSmsSend"><i class="fa fa-spinner fa-spin" v-if="smsLoader"></i> {{ smsLoader ? ' Sending' : 'Send' }}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Form } from 'vee-validate'
    import { mapState } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const ContactSmsComponent = defineAsyncComponent(() => import('@/pages/contact/components/SmsComponent'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'SendSms',

        data () {
            return {
                form: {
                    contact_id: '',
                    sms_message: '',
                },
                validContacts: [],
            }
        },

        props: {
            modelValue: Boolean,
            contacts: Array,
            defaultSms: {
                type: String,
                default: '',
            },
            contactsData: {
                type: Object,
                default: () => ({}),
            }
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            ContactSmsComponent,
        },

        watch: {
            contacts (contacts) {
                const vm = this;

                vm.form.contact_id = contacts.join(',');
            },

            modelValue (value) {
                const vm = this;
                if (value == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }

                if (value && vm.defaultSms) {
                    vm.form.sms_message = vm.defaultSms;
                }

                if (value) {
                    setTimeout(() => {
                        vm.filterSelectedContacts(vm.contactsData);
                    }, 1000);
                }
            },
        },

        computed: mapState({
            smsLoader: state => state.contactModule.smsLoader,
        }),

        methods: {
            closeModal () {
                const vm = this;

                vm.form.sms_message = '';
                vm.$emit('update:modelValue', false);
            },

            handleSmsSend () {
                const vm = this;
                const smsComponent = vm.$refs['sms-component'];

                smsComponent.handleBulkSMSSend();
            },

            filterSelectedContacts (data) {
                const vm = this;
                const smsDisabled   = [];
                const noNumber      = [];

                vm.validContacts = [];

                if (data && data.length && vm.contacts.length) {
                    data.forEach((contact) => {
                        if (vm.contacts.includes(contact.id)) {
                            if (!contact.sms_enabled) {
                                smsDisabled.push(contact.name);
                            } else if (!contact.number) {
                                noNumber.push(contact.name);
                            }

                            if (contact.number && contact.sms_enabled) {
                                vm.validContacts.push(contact.id);
                            }
                        }
                    })
                }

                if (smsDisabled.length && noNumber.length) {
                    const options = Helper.swalWarningOptions('Oops!', `SMS will be sent to ${vm.validContacts.length} of your ${vm.contacts.length} total contacts because ${smsDisabled.length} ${smsDisabled.length > 1 ? 'contacts' : 'contact' } have SMS sending disabled and ${noNumber.length} ${noNumber.length > 1 ? 'contacts' : 'contact' } does not have a valid mobile number.`, 'Ok');

                    Swal.fire(options);
                } else if (smsDisabled.length) {
                    const options = Helper.swalWarningOptions('Oops!', vm.validContacts.length + smsDisabled.length == 1 ? `SMS sending is disabled for selected contact.` : `SMS will be sent to ${vm.validContacts.length} of your ${vm.contacts.length} total contacts because ${smsDisabled.length} ${smsDisabled.length > 1 ? 'contacts' : 'contact' } have SMS sending disabled.`, 'Ok');

                    Swal.fire(options);
                } else if (noNumber.length) {
                    const options = Helper.swalWarningOptions('Oops!', vm.validContacts.length + noNumber.length == 1 ? `Selected contact does not have a valid mobile number on file.` : `SMS will be sent to ${vm.validContacts.length} of your ${vm.contacts.length} total contacts because ${noNumber.length} ${noNumber.length > 1 ? 'contacts' : 'contact' } does not have a valid mobile number.`, 'Ok');

                    Swal.fire(options);
                }
            }
        },
    }
</script>
